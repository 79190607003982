body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.marquee-container {
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  display: flex;
  height: 8vh;
  margin: 0;
  padding: 0;
}

.marquee {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: scroll-down 25s linear infinite;
  margin: 0;
  padding: 0;
}

.marquee-item {
  display: inline-block;
  padding: 0;
  font-size: 0.9rem;
  white-space: nowrap;
  margin: 0.4rem;
}

.marquee-item:nth-child(odd) {
  color: #294b82;
}

.marquee-item:nth-child(even) {
  color: #F7133C;
}


@keyframes scroll-down {
  from {
    transform: translateY(430%);
  }
  to {
    transform: translateY(-430%);
  }
}

/* For smaller screens */
@media (max-width: 768px) {
  @keyframes scroll-down {
    from {
      transform: translateY(380%); 
    }
    to {
      transform: translateY(-380%);
    }
  }
}

